.advanced-bar-chart-container {
  //margin: 3rem 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .y-axis {
    cursor: default;

    text {
      user-select: none;
      -moz-user-select: none;
      -khtml-user-select: none;
      -webkit-user-select: none;
      -o-user-select: none;
    }
  }

  .negative-bar {
    fill: #ff0000;
  }
}