.edit-model-component, .taxonomy-model-component {
  //.MuiButton-root {
  //}
  padding: 2em;
  min-width: 50em;
  min-height: 15em;

  &.move-model-component {
    min-width: 70em;
    min-height: 16em;
  }

  .selection-overview-list {
    margin-top: 2em;
  }

  &.confirm-send-for-approval-component {
    .MuiFormControl-root {
      margin-top: 2em;
    }
  }
}

.taxonomy-editor-page {

  .page-title {
    display: inline;
    font-size: 3.75rem;
  }

  .taxonomy-title {
    margin-left: 1em;
    display: inline;
    font-weight: lighter;
    font-size: 2.125rem;
  }

  .MuiInput-root {
    margin-left: 1em;
  }


  .toolbar-btn,
  .toolbar-composite-btn {
    line-height: 1.2;
    min-height: 3em;
    text-align: left;
    text-transform: none;
  }

  .btn-text {
    width: 5em;
  }

  .two-icon-btn .btn-text {
    width: 4em;
  }

  #taxonomy-editor-button-toolbar {
    .toolbar-btn,
    .toolbar-composite-btn {
      margin-left: 2em;
    }

    #taxonomy-editor-button-settings-popper {
      z-index: 100;

      .overlay-container {
        display: flex;
        flex-direction: column;
      }

      .toolbar-btn {
        margin-left: 0;
        margin-top: 2em;
        background-color: white;

        .btn-text {
          width: 6.5em;
        }
      }
    }
  }

  .history-popper {
    min-width: 6em;
    z-index: 100;
  }

  .categorization-v1-page-content {
    margin-top: 2em;
    min-height: 40em;

    .MuiAlert-root {
      margin-top: 2em;
    }

    .loading-mid-page {
      margin-top: 15em;
    }

    .taxonomy-editor-viz {
      min-height: 40em;
      // margin-top: 2em;
    }

    .wider-button {
      width: 10em;
    }
  }

  .taxonomy-editor-viz {
    width: 100%;
    //border: solid;
  }

  .history-popper {
    ul {
      padding: 0;

      &:hover li {
        background: rgba(67, 97, 133, 0.2);
      }

      li:hover ~ li {
        background: none;
      }
    }
  }

  &.viewer {
    .categorization-v1-page-content {
      margin: 0;
    }

    .taxonomy-editor-viz {
      margin-top: 1em;
    }
  }
}

.MuiGrid-root.top-toolbar-blocked {
  margin-right: auto;
  margin-left: 3em;
}

.MuiButton-root.health-btns {
  margin: 0.3em;
  margin-top: 1em;
}

.taxonomy-health-checker-toolbar {
  display: flex;
  justify-content: space-between;
}

.hc-btn-group {
  display: flex;
  justify-content: space-between;
}

.MuiButtonBase-root.acc-rej-edit {
  text-align: center;
  padding: 0;
  width: 20px;
}

.MuiGrid-root.loading-mid-page {
  text-align: center;
  margin: auto;
  display: flex;
  justify-content: space-around;
  padding-top: 8em;
  padding-bottom: 8em;
}

.demo-update-model-component {
  &.edit-model-component {
    min-width: 60em;
  }

  .form-title {
    color: rgba(25, 48, 79, 0.54);
    font-weight: bold;
    font-size: 1rem;
  }

  .MuiFormControl-root {
    margin-bottom: 2em;
  }

  #demo-taxonomy-editor-modal-field3-root {
    margin-bottom: 3em;
  }

  #demo-taxonomy-editor-modal-field3-editor-container {
  }

  #demo-taxonomy-editor-modal-field3-editor {
    border: 1px solid rgb(226, 232, 240);
    border-radius: 4px;
    width: 35em;
    min-height: 13em;
  }
}
