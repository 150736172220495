.debug-components {

  margin-left: 1em;

  &,
  .MuiButton-root {
    font-family: monospace;
  }

  .debug-value {
    &.value-true {
      background-color: #DAF2DA;
      color: darkgreen;
    }

    &.value-false {
      background-color: #f2dada;
      color: darkred;
    }

    &.value-other {
      background-color: whitesmoke;
    }
  }

  pre {
    display: inline-block;
    margin: 0;
  }

  div, button {
    display: inline-block;
    margin: 0 0.5em;
    padding: 0;
  }

  .MuiButton-root {
    line-height: 1;
    vertical-align: inherit;
  }
}
