$colorPartsTableHead: #0C3353;
//$colorPartsTableHeadFont: #ffe7c9;
$colorPartsTableHeadFont: #fff;
$colorPartsTableRow1: #fffff;
$colorPartsTableRow2: #ededed;

$colorTableSelected: #DEA01E;

$colorSuppliers: #f5c27c;
$colorSuppliersHighlight: #f8dab2;
$colorSpend: #75a6e6;
$colorSpendHighlight: #b8cbe6;

$colorMainHeader: #193150;
$colorMainHeaderHighlight: #0d4066;

$colorMainBlue: $colorMainHeader;

$colorDashboard: #193150;

$colorRed: #FF3435;
$colorRedHighlight: #ff7373;
$colorRedText: #FFF;
$colorGreen: #08A57A;
$colorGreenHighlight: #4ba68d;
$colorYellow: #FAA915;
//$colorPrimaryBlue: #091344; // New from moghavi
$colorPrimaryBlue: #19304fe6; // Old from dennis
$colorSecondaryBlue: #19304fbf; // Old from dennis
$colorAccentBlue: #0262F2;
$colorGray: #D1D6DD;
$colorGrayLight: #F6F6F6;
