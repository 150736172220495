.data-item-dragging {
  background: yellow;
  font-family: sans-serif;
  border-radius: 4px;
  border: 1px solid #0C3352;
  margin: 6px;
  padding: 10px;

  .item-name {
    font-weight: bold;
    font-size: medium;
    color: #193150;
    word-break: break-word
  }
}


.data-ingestion {

  ul,
  li {
    margin: 0;
    padding: 0;
    list-style: none;
  }

  .react-beatiful-dnd-copy ~ div {
    transform: none !important;
  }


  .react-beatiful-dnd-copy {
    background: lightblue;
    border-radius: 4px;
    border: 1px solid #0C3352;
    margin: 6px 0px;
    padding: 10px;
    overflow-x: hidden;
  }

  .react-beatiful-dnd-copy ~ [data-rbd-placeholder-context-id] {
    display: none !important;
  }

  .client-column {
    max-height: 40em;
    overflow-y: scroll;
  }

  .mithra-column {
    border: 1px solid #707070;
    border-radius: 4px;
    background-color: #19315033;
    height: 100%;

  }


  .dataset-mapping-title-grid {
    margin-top: 2em;
    margin-bottom: 2em;
  }


  .container {
    margin: 8px;
    flex-direction: column;
  }

  .mithra-columns-container {
    max-height: 40em;
    overflow-y: scroll;
  }

  .row {
    margin: 6px 0px 6px 0px;
    display: flex;

    .required {
      color: #E62D15;
      font-size: 2.5em;
      display: flex;
      justify-content: flex-end;
      margin-right: 0.15em;
    }
  }

  .typography {
    padding: 1em;
  }

  .wrapper {
    display: flex;
    justify-content: space-around;
  }

  .true {
    background-color: orangered;
  }

  .item {
    border-radius: 4px;
    border: 1px solid #0C3352;
    margin: 6px 0px 6px 0px;
    padding: 10px;
    background-color: white;
    //overflow-x: hidden;
  }

  .ai-suggestion {
    background-color: lightblue;
  }

  .item-name {
    font-weight: bold;
    font-size: medium;
    color: #193150;
    word-break: break-word
  }

  .item-example {
    font-weight: normal;
    font-size: medium;
    color: #707070;
    word-break: break-word
  }

  .disabled {
    opacity: 0.5;
    background-color: #FF575766;
  }
}


@import '../../../style/variables';
@import '../../../style/colors';

$grayP: '44%';
.data-check-wrapper {
  margin-top: 2em;
  min-height: 5em;
  width: 100%;

  .data-check-table {
    max-width: 100%;
    max-height: 20em;
    overflow-y: scroll;
  }

  .icon path {
    fill: $colorDashboard;
  }

  .icon.stroke-adj path {
    stroke: $colorDashboard;
  }

  .MuiTypography-root {
    color: $colorDashboard;
    max-height: 1em;
  }

  //padding-top: 1em;
  //padding-bottom: 3em;
  .dashboard-row {
    margin-top: 5.5rem;

    .dashboard-row-title {
      margin-bottom: 2rem;

      .MuiTypography-root {
        font-size: 2.5rem;
        margin-bottom: 0;
        min-height: 1em;
      }
    }

    .dashboard-row-subtitle {
      margin-bottom: 6rem;

      .MuiTypography-root {
        font-size: 2.857rem;
        margin-bottom: 0;
        font-weight: bold;
        min-height: 1em;
      }
    }

    .dashboard-row-content {
    }

    .dashboard-card {
      display: block;
      position: relative;
      width: 100%;
      margin-bottom: 3.8em;

      &.btn-base {
        text-align: left;
        font-size: inherit;

        .MuiCard-root {
          padding-top: 1.357em;
          padding-bottom: 1.357em;

          border: solid;
          border-color: $colorDashboard;
          border-radius: 5px;
          border-width: 1px;
          //min-height: 8.7em;
        }
      }

      &.no-btn-base {
        padding-top: 1.357em;
        padding-bottom: 1.357em;

        border: solid;
        border-color: $colorDashboard;
        border-radius: 5px;
        border-width: 1px;
        //min-height: 8.2em;
      }


      .dashboard-card-container {
        min-height: 6em;

        .dashboard-card-container-r {
          //flex-basis: 75%;
        }
      }

      .MuiTypography-root {
        font-size: 20px;
        line-height: 1.2;
      }

      .card-title {
        margin-bottom: .85em;
      }

      &.only-title .card-title {
        margin-top: .5em;
      }

      .card-content {
        .value {
          font-weight: bold;

          &.green {
            color: #06A77D
          }

          &.red {
            color: #FF5757
          }

          &.gray {
            opacity: $grayP;
          }
        }

        .text {
          font-size: 15px;
          opacity: $grayP;
        }
      }

      .avatar {
        line-height: 0;
      }

      .dashboard-badge {
        position: absolute;
        right: 0;
        bottom: 0;

        width: 60px;
        height: 60px;
        background-image: linear-gradient(to bottom right, transparent 50%, $colorDashboard 0);
        border-bottom-right-radius: 5px;

        p {
          color: white;
          position: absolute;
          right: 0.45em;
          bottom: .25em;
          line-height: 1;
          text-align: right;
        }
      }

      &.btn-base.is-trial > .MuiCard-root,
      &.no-btn-base.is-trial {
        opacity: .6;
        background-color: #f2f2f2;
        cursor: no-drop;
      }
    }
  }

  .dashboard-grid-alert {
    margin-top: 3em;
    margin-bottom: -3em;
  }

}
