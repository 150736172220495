@import 'src/style/variables';
@import 'src/style/colors';

$wideSearchBoxWidth: 400px;
$narrowSearchBoxWidth: 250px;

.advanced-dashboard-page {
  .filter-container {
    display: flex;
    grid-gap: 5px;
    justify-content: space-around;
    min-height: 10em;
    max-height: 20em;
  }

  // This is a hack to preserve line space in case of multi line select
  .MuiAutocomplete-root:not(.Mui-focused) {
    .MuiAutocomplete-tag.MuiChip-root {
      width: calc(100% - 60px);
    }

    span.MuiAutocomplete-tag {
      width: 1px;
      margin-left: 0;
      margin-right: 0;
    }

    input {
      width: 40px;
      min-width: 40px;
    }
  }

  .advanced-opportunity-table {
    //min-height: 30em;
    .no-data {
      .MuiTableCell-root {
        text-align: center;
        height: 5 * 4em; // 4em per row
      }
    }

    .MuiTableRow-root {
      //height: 3em;
    }

    .MuiTableCell-root {
      padding: 0.5em 0.5em;
    }
  }

  .advanced-supplier-breakdown-table {
    min-height: 70em;

    .no-data {
      .MuiTableCell-root {
        text-align: center;
        height: 10 * 4em; // 4em per row
      }
    }

    .MuiTableRow-root {
      height: 4em;
    }

    .MuiTableCell-root {
      padding: .2em 1em;
    }
  }

  .supplier-search-autocomplete-wrapper {
    position: relative;
    width: $narrowSearchBoxWidth;

    .MuiAutocomplete-root {
      z-index: 100;
    }

    .MuiInputLabel-root {
      z-index: 101;
    }

    .MuiAutocomplete-inputRoot {
      // Allows for overflowing
      background-color: white;
    }

    &.wide {
      // If the wide tag is set we want to show the bar very wide when it's opened
      min-height: 10em;

      .MuiAutocomplete-root.Mui-focused {
        position: absolute;
        right: 0;
        top: -5px;
      }

      .MuiAutocomplete-root.Mui-focused {
        width: 550px;
      }

      &.extra-wide .MuiAutocomplete-root.Mui-focused {
        width: 700px;
      }
    }
  }

  .parent-supplier-search-autocomplete-wrapper {
    position: relative;
    width: $narrowSearchBoxWidth;

    .MuiAutocomplete-root {
      z-index: 100;
    }

    .MuiInputLabel-root {
      z-index: 101;
    }

    .MuiAutocomplete-inputRoot {
      // Allows for overflowing
      background-color: white;
    }

    &.wide {
      // If the wide tag is set we want to show the bar very wide when it's opened
      min-height: 10em;

      .MuiAutocomplete-root.Mui-focused {
        position: absolute;
        right: 0;
        top: -5px;
      }

      .MuiAutocomplete-root.Mui-focused {
        width: 550px;
      }

      &.extra-wide .MuiAutocomplete-root.Mui-focused {
        width: 700px;
      }
    }
  }

  .adv-dashboard-country-filter {
    width: $narrowSearchBoxWidth;
  }

  .adv-dashboard-filter-continent {
    width: $narrowSearchBoxWidth;
  }

  .date-range-picker {
    width: $narrowSearchBoxWidth;
  }

  .MuiAlert-standardInfo {
    color: #0C3353;
    margin: 0 2em 2em;

    .MuiAlert-action {
      align-self: center;
      margin-right: 1em;
    }
  }
}

.advanced-graph-container {
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 1rem;

  @media (min-width: 1200px) {
    grid-template-columns: 1fr 1fr;
  }
}

.advanced-line-graph-container {
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 1rem;
}

.advanced-looker-tables {
  display: flex;
  flex-direction: column;
  justify-content: left;
  grid-gap: 3rem;
  margin-top: 3rem;
}

.advanced-looker-tables > div {
  display: flex;
  flex-direction: column;
}

.advanced-dashboard-title {
  color: $colorMainBlue;
  margin-left: 2em;
  margin-top: 3em;
  margin-bottom: 1em;
}

.bubble-map-container {
}