@import "src/style/variables";

.koi-ppv-page {
  .bar-chart-container {
    width: 100%;
    height: 352px;
  }

  .key-value-caption {
    width: 80%;
    margin-top: 3em;
    margin-right: auto;
    margin-left: auto;
    font-size: 1.2rem;
  }

  @media (min-width: 1400px) {
    .bar-chart-container {
      width: 75%;
      margin: 0 auto;
    }
    .bar-chart-title {
      margin-left: 34%;
    }
  }

  .ppv-table-section {
    margin-top: 10em;
  }

  .parts-table {
    table {
      min-height: 59em;
    }

    .col-p__description {
      width: 4.5em;
    }

    margin-bottom: $pageItemMargin;
  }
}
