@import '../../style/variables';
@import '../../style/colors';

.categorization-page {
  // Previously named: .custom-page

  padding: $pageMargin;
  min-height: 40em;

  .MuiTypography-root:not(.MuiAlertTitle-root) {
    color: $colorDashboard;
    max-height: 1.5em;
  }

  .header {
    margin-top: $pageHeaderMargin;
    //margin-bottom: $pageHeaderMargin;

    .align-with-stepper {
    }
  }

  .key-values {
    font-size: 4em;
    margin: 0.5em 0 0.5em 0;
  }

  .single-bar-chart {
    margin-top: 2em;
    margin-bottom: 2em;

    .left-overflow-area {
      padding-right: 1em;
    }

    .bar-wrapper.old .bar {
      fill: #19315033;
    }

    .bar-wrapper.new .bar {
      fill: #193150;
    }
  }

  .bar-chart-container {
    &.l1 {

    }

    &.l2 {
      margin-left: 1.5em;
    }

    &.l3 {
      margin-left: 2em;
    }

    &.l4 {
      margin-left: 2.5em;
    }
  }

  .nav-all-cats-btn {
    margin-top: 2em;
    z-index: 10;
  }

  .single-bar-chart {
    &.uncat {
      .MuiTypography-root {
        color: $colorRed;
      }

      .bar-wrapper.old .bar {
        fill: #FF575733;
      }

      .bar-wrapper.new .bar {
        fill: $colorRed;
      }
    }

    .sub-bar {
      fill: $colorRed;
    }
  }

  .key-value-container {
    .value-warning {
      color: $colorRed;
    }

    &.narrow-margin {
      // TODO: Is thie top marging needed?
      margin-top: 1em;

      margin-bottom: 0;

      > div {
        // TODO: The following line negates the effects of the SupplierNormalization scss
        margin-bottom: 0;
      }
    }

    &.wide-margin {
      margin-top: 3em;
      margin-bottom: 3em;
    }
  }

  .review-filter-bar {
    .typed-search-component {
      &.has-options {
        display: flex;

        .typed-search-field-type {
          min-width: 11em;
          flex: 1;
        }

        .typed-search-field {
          flex: 3;
        }
      }
    }

    .grouped-switch {
      margin-left: 2em;
    }

    margin-top: 2em;
    margin-bottom: 0.5em;

    .root-selection-component {
      vertical-align: initial;
      margin-right: 1em;
    }
  }

  .grouped-categorization-review-main-row__suggested-categories {
    background: $colorMainBlue;
    color: white;

    svg {
      color: white;
    }
  }

  .approved {
    background: $colorGreen;
    color: white;
  }

  .pending {
    background: #FAA915;
    color: white;
  }

  .rejected {
    background: #FF3435;
    color: white;
  }

  .not-send {
    background: darkgrey;
    color: white;
  }

  .mixed {
    background: $colorPrimaryBlue;
    color: white;
  }

  .categorization-welcome-page.button-only {
    margin-top: 5em;
  }

  .MuiAlert-standardInfo {
    background-color: rgba(2, 98, 242, 0.1);
  }

  .review-table-head-alerts-container {
    .MuiAlert-root {
      margin-bottom: 1em;
    }
  }
}

.debug-btn.MuiButton-root {
  background-color: #787800;
  padding: .1em;
  margin: .1em;
  font-size: .6rem;
}

.categorization-review-model-component {
  .checkbox-width {
    min-width: 3.5em;
  }

  .by-year-select-field-wrapper {
    margin: 0;
  }
}
