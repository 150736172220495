.ai-model-busy {
  .header {
    margin-top: 4em;
  }

  .progress-container {
    width: 600px;
    margin-top: 4em;
  }

  .instructions-text {
    margin-top: 1em;
  }

  .action-btn-container {
    margin-top: 4em;
  }
}