@import "src/style/colors";
.data-ingestion {

  &.data-overview-page {
    min-height: max(70vh, 50em);
  }

  .error-alert-container {
    margin-top: 4em;
  }

  .dataset-overview-table-titles {
    margin-top: 4em;
  }

  .dataset-overview-table-content {
    margin-top: 2em;
  }

  .dataset-overview-actions {
    margin-top: 4em;
    margin-bottom: 4em;
  }

  h2 {
    font-size: 1.5em;
    margin: 0.83em 0;
    font-weight: lighter;
    color: #193150;
  }

  h4 {
    color: #0C3352;
  }

  h6 {
    font-weight: lighter;
    color: #193150;
  }

  .data-ingestion-stepper {
    margin-top: 4em;
  }

  .upload-file-button {
    min-width: 10em;
  }

  .loading-spinner-pie {
    margin: 22em auto;
  }

  &.no-data {
    .dataset-overview-actions {
      margin-top: 22em;
      margin-bottom: 22em;
    }
  }
}

