.MuiTabs-root.thick-indicator .MuiTabs-indicator {
  height: 4px;
}

.no-wrap {
  overflow-wrap: normal;
}

.large-icon-btn .MuiButton-startIcon svg {
  font-size: 2em;
}

.MuiGrid-root.one-line {
  height: 1.43em;
}

.progress-container {
  height: 4px;
}

.one-line-p {
  height: 1.5em;
}
